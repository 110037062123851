import React from "react";
import Helmet from "react-helmet";
import { pathOr } from "ramda";

import useSiteMetaData from "../../hooks/useSiteMetaData/useSiteMetaData";
import { siteMetaData } from "../../hooks/useSiteMetaData/useSiteMetaData.types";

import { SEOProps } from "./Seo.types";

export const SEO: React.FC<SEOProps> = ({ description, lang, meta, title, image }) => {
  const siteMetadata: siteMetaData = useSiteMetaData();

  const metaDescription = description || siteMetadata.description;
  const metaImage = pathOr(siteMetadata.image, [`childImageSharp`, `fluid`, `src`], image);

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: `og:image`,
          content: metaImage,
        },
      ].concat(meta || [])}
    />
  );
};
